<template>
  <v-container class="pa-0 text-center fill-height" fluid>
    <v-row class="white fill-height" align-content="center" justify="center" no-gutters>
      <v-col cols="12">
        <v-img alt="Success" contain height="175" src="../assets/success_check.gif" />
      </v-col>
      <v-col cols="12" class="mt-2">
        <v-row no-gutters>
          <v-col cols="12">
            <p class="font-weight-bold mb-1" style="color: #3d3f3c" v-if="redeemedFreeparking">
              Redeemed
            </p>
            <p class="font-weight-bold mb-1" style="color: #3d3f3c" v-else>
              Validated by {{ validatedMerchant }}
            </p>
            <!-- <p class="font-weight-bold mb-0" style="color: #3d3f3c">
              by
            </p> -->
          </v-col>
          <!-- <v-col cols="12">
            <p class="font-weight-bold text-h4" style="color: #3d3f3c">
              {{ validatedMerchant }}
            </p>
          </v-col> -->
        </v-row>
      </v-col>
      <!-- <v-col cols="12" v-else>
        <p class="font-weight-bold" style="color: #3d3f3c">
          Validation successful
        </p>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import { EventBus } from "@/lib/EventBus";
// import loggerHelper from "../loggerHelper";
import { dateToTimeZoneDate } from "@/utils/formatDateTime";
export default {
  name: "SuccessValidationBooking",
  data: () => ({}),
  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
      validatedMerchant: "getterValidatedMerchant",
      redeemedFreeparking:'getterIsFreeParkingRedeemed'
    }),
  },
  mounted() {
    setTimeout(async () => {
      await this.getBookingState();
    }, 4000);
  },
  methods: {
    async getBookingState() {
      if (this.bookingId != null) {
        try {
          var bDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + this.bookingId
          );
          let bookingState = bDetails.data?.data?.booking?.state
            ? bDetails.data.data.booking.state
            : null;
            this.$store.commit(
              "SET_BOOKING_DETAILS",
              bDetails.data?.data ? bDetails.data.data : null
            );
          if (bookingState == 8 || bookingState == 10) {
            if (bDetails?.data?.data?.booking?.TypeOfBooking == 'Self reservation' && dateToTimeZoneDate(new Date(bDetails?.data?.data?.booking?.revEnd), bDetails?.data?.data?.zone?.timezone) > dateToTimeZoneDate(new Date()), bDetails?.data?.data?.zone?.timezone) {
              this.$router.replace({path: '/checkin'});
            }
            else {
              await this.$router.replace({ path: "/checkout" });
              if(this.bookingDetails?.booking?.autoP2PDetails)
             {
              EventBus.$emit('AUTO_P2P_BID', this.bookingDetails)
             }
              EventBus.$emit('SET_FREE_BOOKING')
            }
          } else if (bookingState == 2) {
            if (bDetails.data?.data?.booking?.entryMode == "OD") {
              this.$router.replace({ path: "/odExtension" });
              return;
            }
            await this.$router.replace({ path: "/checkedin" });
            EventBus.$emit('GET_FREE_BOOKING')
          } else {
            setTimeout(async () => {
              await this.getBookingState();
            }, 4000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
<style lang="less"></style>
